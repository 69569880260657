import { fullName, resourceUrl } from '@/utils/dataExtractors'
import { pdfStringToCents } from "@/utils/billHelper"
export const adminOverviewColumns = (actions: {[key: string]: Function}) => {
  return [
    {
      component: () => import("@/components/shared/table/cells/ButtonsCell.vue"),
      key: 'seller.attributes.firstName',
      title: "Name",
      // label: "Name",
      sortable: true,
      options: {
        justification: 'start',
        buttons: [
          (data: any) => ({
            action: actions.focusOnSeller,
            variant: 'link',
            title: fullName(data.item.seller),
            classes: 'p-0'
          })
        ]
      }
    },
    // {
    //   component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    //   label: "Name",
    //   title: "Name",
    //   key: 'seller.attributes.firstName',
    //   sortable: true,
    //   options: {
    //     contentFunction: (data: any) => fullName(data.item.seller)
    //   }
    //   // options: {type: 'date'}
    // },
    {
      component: () => import("@/components/shared/table/cells/ValueCell.vue"),
      title: "Gezahlt",
      key: 'paid',
      options: {
        contentFunction: (data: any) => {
          const paid = data.item.bills.filter((b) => b.attributes.paid).length
          return `${paid} von ${data.item.bills.length}`
        }
      }
    },
    {
      component: () => import("@/components/shared/table/cells/ValueCell.vue"),
      title: "Rechnungs#",
      key: 'currentBill.attributes.billNr',
      options: {
        cellClassFunction: (data: any) => {
          return data.item.currentBill.attributes.paid ? 'text-success' : 'text-danger'
        }
        // type: 'list',
        // contentFunction: (data: any) => data.item.bills.map((b)=>b.attributes.billNr).join(', ')
      }
    },
    {
      component: () => import("@/components/shared/table/cells/ValueCell.vue"),
      title: "Schulden",
      key: 'debt',
      sortable: true,
      options: {
        contentFunction: (data: any) => {
          if (!data.item.currentPdf) return 'Wird geladen'
          return data.item.currentPdf.earnings.all.debt_string.replace('-','')
          // return (totalCents/100).toString().replace('.',',')
        }
      }
    },
    {
      component: () => import("@/components/shared/table/cells/ValueCell.vue"),
      title: "TotalSumme",
      key: 'all',
      sortable: true,
      options: {
        cellClassFunction: (data: any) => {
          if (data.item.all.includes('-')) {
            return 'text-warning'
          } else { return '' }
        }
      //   contentFunction: (data: any) => {
      //     if (data.item.pdfs.length === 0) return 'Wird geladen'
      //     const totalCents = data.item.pdfs.reduce((res: number, p:any) => {
      //       return res += pdfStringToCents(p.annual_sum)
      //     },0)
      //     return (totalCents/100).toString().replace('.',',')
      //   }
      }
    },
    {
      component: () => import("@/components/shared/table/cells/ValueCell.vue"),
      title: "Jahressumme",
      key: 'anno',
      sortable: true,
      options: {
        cellClassFunction: (data: any) => {
          if (data.item.anno.includes('-')) {
            return 'text-warning'
          } else { return '' }
        }
      //   contentFunction: (data: any) => {
      //     if (data.item.pdfs.length === 0) return 'Wird geladen'
      //     const totalCents = data.item.pdfs.reduce((res: number, p:any) => {
      //       return res += pdfStringToCents(p.annual_sum)
      //     },0)
      //     return (totalCents/100).toString().replace('.',',')
      //   }
      }
    },
    {
      component: () => import("@/components/shared/table/cells/ValueCell.vue"),
      title: "Monatssumme",
      key: 'monthTotal',
      sortable: true,
      options: {
        // contentFunction: (data: any) => {
        //   if (data.item.pdfs.length === 0) return 'Wird geladen'
        //   const totalCents = data.item.pdfs.reduce((res: number, p:any) => {
        //     return res += pdfStringToCents(p.avis_totals.total)
        //   },0)
        //   return (totalCents/100).toString().replace('.',',')
        // },
        cellClassFunction: (data: any) => {
          if (data.item.monthTotal < 0) {
            return 'text-warning'
          } else { return '' }
        }
      }
    },
    // {
    //   component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    //   label: "Anreise",
    //   title: "Anreise",
    //   key: 'attributes.cgiArrival',
    //   options: {type: 'date'}
    // },
    // {
    //   component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    //   title: "Abreise",
    //   key: 'attributes.cgiDeparture',
    //   options: {type: 'date'},
    // },
    // {
    //   component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    //   title: "Preis",
    //   key: 'attributes.price',
    //   seller: true
    // },
    // {
    //   component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    //   title: "provPfS",
    //   key: 'attributes.provPfs',
    //   sortable: true,
    //   seller: false
    // },
    // {
    //   component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    //   label: "provSeller",
    //   title: "provSeller",
    //   key: 'attributes.provSeller',
    //   sortable: true,
    //   seller: true
    // },
    {
      component: () => import("@/components/shared/table/cells/ButtonsCell.vue"),
      key: "actions",
      title: "Aktionen",
      sortable: false,
      options: {
        buttons: [
          {
            title: 'PDF neu erstellen',
            icon: ['fad', 'file-pdf'],
            action: actions.renderPdf,
          },
          {
            title: 'Als bezahlt festlegen',
            icon: ['fal', 'comment-dollar'],
            action: actions.setPaid,
          },
          {
            title: 'Details',
            icon: ['far', 'eye'],
            action: actions.openPdf,
          },
          // {
          //   title: 'Löschen',
          //   icon: ['fad', 'trash'],
          //   action: actions.deleteSegment,
          // }
        ]
      }
    },
  ]
}

export const adminSellerYearColumns = (actions: {[key: string]: Function}) => {
  return [
    {
      component: () => import("@/components/shared/table/cells/ButtonsCell.vue"),
      key: 'bill.attributes.billNr',
      label: "Bill#",
      options: {
        justification: 'start',
        buttons: [
          (data: any) => ({
            action: actions.focusOnBill,
            variant: 'link',
            title: data.item.bill.attributes.billNr,
            classes: 'p-0'
          })
        ]
      }
    },
    // {
    //   component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    //   label: "Bill#",
    //   title: "Bill#",
    //   key: 'bill.attributes.billNr',
    //   sortable: true,
    // },
    {
      component: () => import("@/components/shared/table/cells/ValueCell.vue"),
      label: "Schulden",
      title: "Schulden",
      key: 'pdf.earnings.all.debt',
      options: {
        contentFunction: (data) => {
          if (data.item.pdf) {
            const year = data.item.bill.attributes.year
            return data.item.pdf.earnings[year].debt_string
          }
        }
      }
    },
    {
      component: () => import("@/components/shared/table/cells/ValueCell.vue"),
      label: "Leistungen",
      title: "Leistungen",
      key: 'pdf.charge_totals.gross_value_string',
    },
    {
      component: () => import("@/components/shared/table/cells/ValueCell.vue"),
      label: "Provision",
      title: "Provision",
      key: 'pdf.gross_provision_total_string',
    },
    {
      component: () => import("@/components/shared/table/cells/ValueCell.vue"),
      label: "Gutschriften",
      title: "Gutschriften",
      key: 'pdf.credit_totals.gross_value_string',
      options: {
        contentFunction: (data) => {
          if (data.item.pdf) return '-'+data.item.pdf.credit_totals.gross_value_string
        }
      }
    },
    {
      component: () => import("@/components/shared/table/cells/ValueCell.vue"),
      label: "Avis",
      title: "Avis",
      key: 'pdf.manual_avis_totals.total_string',
    },
    {
      component: () => import("@/components/shared/table/cells/ValueCell.vue"),
      label: "Total",
      title: "Total",
      key: 'pdf.avis_totals.total_string',
    },
  ]
}

export const adminSellerBillProvisionColumns = [
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    key: "index",
    label: "Index",
    options: {contentFunction: (d:any) => d.index+1}
  },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    key: "label",
    label: "Name",
  },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    key: "full_value",
    label: "Provision",
  },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    key: "start",
    label: "Start",
  },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    key: "end",
    label: "End",
  },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    key: "duration",
    label: "Tage",
  },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    key: "net_value",
    label: "Netto",
  },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    key: "gross_value",
    label: "Brutto",
  },
]

export const adminSellerBillLeadColumns = [
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    key: "index",
    label: "Index",
    options: {contentFunction: (d:any) => d.index+1}
  },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    key: "label",
    label: "Name",
  },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    key: "created_at",
    label: "Erstellung",
  },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    key: "net_value",
    label: "Netto",
  },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    key: "gross_value",
    label: "Brutto",
  },
]