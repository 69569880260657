
  import { computed, defineComponent, ref, watch, getCurrentInstance } from "vue"
  import { Segment } from "@/models/segments"
  import { fullName } from "@/utils/dataExtractors"
  import { computeSellerBillOverviewData, sellerBillKeyData, segmentDuration, segmentSellerProvision, pdfStringToCents, floatTopdfString, lastMonth } from "@/utils/billHelper"
  import { generateYearSelectOptions, generateMonthSelectOptions, round, stringSort, localIndex } from "@/utils/helper"
  import KeyValue from "@/components/shared/KeyValue.vue"
  import BillKeyData from "@/components/shared/BillKeyData.vue"
  import { confirm, prompt } from '@/utils/interactionModals'
  import { ResourceObject } from '@/models/jsonapi'
  import FileMetaData from "@/components/shared/FileMetaData.vue"
  import { FileMetaData as FMDModel } from "@/models/file_meta_datas";
  import SellerBillEntry from "@/components/admin/SellerBillEntry.vue"
  import { adminOverviewColumns, adminSellerYearColumns, adminSellerBillProvisionColumns as provisionColumns, adminSellerBillLeadColumns as leadColumns} from "@/view_scripts/table_columns/seller_bill_columns"
  // import { Lead } from "@/models/leads";

  export default defineComponent({
    components: {
      KeyValue,
      BillKeyData,
      FileMetaData,
      SellerBillEntry
    },
    setup(_) {
      const root = getCurrentInstance().proxy
      const showLoading = ref(false)
      const yearOptions = generateYearSelectOptions()
      const monthOptions = generateMonthSelectOptions()
      const year = ref<number>(lastMonth(new Date()).getFullYear())
      const month = ref<number>(lastMonth(new Date()).getMonth()+1)
      const bills = ref({})
      const pdfData = ref({})
      const sellers = ref({})
      const fMDs = ref({})
      const selectedSellerId = ref<string|undefined|null>()
      const selectedBillId = ref<string|undefined|null>()
      const billDate = computed({
        get() {
          if (!selectedBill.value?.attributes) {
            return null
          } else if (!selectedBill.value?.attributes.billDate) {
            const today = new Date()
            const [y,m,d] = [today.getUTCFullYear(), today.getUTCMonth(), today.getUTCDate()]
            return `${y}-${('0'+(m+1)).slice(-2)}-${('0'+d).slice(-2)}`
          } else {
            return selectedBill.value?.attributes?.billDate?.split('T')[0]
          }
        },
        set(newValue) {
          selectedBill.value.attributes.billDate = newValue
        }
      })
      const initialDataRequest = () => {
        const getItemCountQuery = {
          'filter[year]': year.value,
          'filter[month]': month.value,
          'relationships': '[]',
          'fields': '[]'
        }
        const billQuery = {
          'filter[year]': year.value,
          'filter[month]': month.value,
          'include': 'seller,file_meta_data',
          'fields[sellers]': 'first_name,last_name',
          'fields[file_meta_datas]': 'all',
          'relationships': 'seller,file_meta_data'
        }
        showLoading.value = true
        root.$store.dispatch('seller_bills/load2',getItemCountQuery).then((r) => {
          billQuery['page[size]'] = Math.ceil(r.meta.totalItems/10)*10
          loadBillData(billQuery)
          selectedSellerId.value = null
        })
      }

      const requestSellerBills = (sellerId:string) => {
        const getItemCountQuery = {
          'filter[seller_id]': sellerId,
          'filter[year]': year.value,
          'relationships': '[]',
          'fields': '[]'
        }
        const billQuery = {
          'filter[seller_id]': sellerId,
          'filter[year]': year.value,
          'include': 'file_meta_data',
          'fields[file_meta_datas]': 'all',
          'relationships': 'file_meta_data,seller',
        }
        root.$store.dispatch('seller_bills/load2',getItemCountQuery).then((r) => {
          billQuery['page[size]'] = Math.ceil(r.meta.totalItems/10)*10
          loadBillData(billQuery)
        })
      }

      const reloadSelectedBill = () => {
        const query = {
          'filter[id]': selectedBillId.value,
          'include': 'file_meta_data',
          'fields[file_meta_datas]': 'all',
        }
        loadBillData(query)
      }

      const loadBillData = (query: {[key: string]: any}) => {
        root.$store.dispatch('seller_bills/load2',query)
          .then((r) => {
            getBillPdfData(r.data)
            return r
          })
          .then(indexResponseData)
      }

      const getBillPdfData = (data: any) => {
        const query = {'filter[id]': data.map((e) => e.id).join(',')}
        root.$store.dispatch('seller_bills/pdf_data', query).then((r) => {
          pdfData.value = {...pdfData.value, ...r.data}
        }).finally(() => {showLoading.value = false})
      }

      const indexResponseData = (response: any) => {
        const indexer = (res, b)=>{ res[b.id]=b; return res}
        const newBills = response.data.reduce(indexer,{})
        bills.value = {...bills.value, ...newBills}
        const carrier = {sellers: {}, file_meta_datas: {}}
        response.included.forEach((e:any) => carrier[e.type][e.id] = e )
        sellers.value = {...sellers.value, ...carrier.sellers}
        fMDs.value = {...fMDs.value, ...carrier.file_meta_datas}
      }

      const sellerIdToBillsIndex:any = computed(() => {
        return Object.values(bills.value).reduce((res: any, bill:any) => {
          const sellerId = bill.relationships?.seller?.data?.id
          if (!sellerId) {alert('Bill ohne Vertriebler: '+bill.id)}
          if (!res[sellerId]) {res[sellerId] = [bill]} else {res[sellerId].push(bill)}
          return res
        }, {})
      })

      const overviewTableItems = computed(() => {
        const result:any[] = Object.values(sellers.value).reduce((res: any, seller: any) => {
          const bills = sellerIdToBillsIndex.value[seller.id].filter((b) => {
            return b.attributes.month === month.value && b.attributes.year === year.value
          })
          // const sortedBills = stringSort(bills, (e) => e.id)
          // sortedBills.forEach((b)=>console.log(b.attributes.billNr))
          const currentBill = bills.filter((b) => b.attributes.paid === false)[0] || bills[0]
          if (currentBill) {
            const currentPdf = pdfData.value[currentBill.id]
            const currentFMD = fMDs.value[currentBill.relationships?.fileMetaData?.data?.id]
              let all = '0'
              let anno = '0'
              let monthTotal = '0'
              // const pdfs = bills.map(b => pdfData.value[b.id]).filter(r=>r!=null)
              if (currentPdf) {
                all = currentPdf.earnings.all.income_string
                anno = currentPdf.earnings[year.value].income_string
                monthTotal = currentPdf.avis_totals.total_string
              }
              const newEntry = { seller, bills, currentBill, currentPdf, all, anno, monthTotal, currentFMD }
              res.push(newEntry)
          }
          return res
        }, []) as any[]
        return stringSort(result, (e)=>e.seller.attributes.firstName)
      })

      const overviewTableOptions = {hideHeaderActions: true, noPagination: true}
      const overviewTableActions = {
        openPdf: (data) => {displayFile(data.item.currentFMD)},
        setPaid: (data) => {setBillPaid(data.item.currentBill)},
        renderPdf: (data) => {updateBill({id: data.item.currentBill.id, usecase: 'render_pdf'})},
        focusOnSeller: (data) => {toggleSelectedSeller(data.item.seller.id)}
      }
      const overviewTableColumns = adminOverviewColumns(overviewTableActions)

      const sellerTableItems = computed(() => {
        if (!selectedSellerId.value) return []
        const bills = sellerIdToBillsIndex.value[selectedSellerId.value].filter((b) => {
          return b.attributes.year === year.value
        })
        const seller = sellers.value[selectedSellerId.value]
        const result:any[] = bills.reduce((res: any, bill: any) => {
          const pdf = pdfData.value[bill.id]
          const newEntry = { bill, pdf, seller }
          res.push(newEntry)
          return res
        }, []) as any[]
        return stringSort(result, (e)=>e.bill.attributes.billNr)
      })

      const sellerTableColumns = adminSellerYearColumns({focusOnBill: (data) => {toggleSelectedBill(data.item.bill.id)}})

      const toggleSelectedSeller = (sellerId:string) => {
        selectedBillId.value = null
        if (selectedSellerId.value === sellerId) {
          selectedSellerId.value = null
        } else {
          showLoading.value = true
          requestSellerBills(sellerId)
          selectedSellerId.value = sellerId
        }
      }

      const toggleSelectedBill = (billId:string) => {
        selectedBillId.value = (selectedBillId.value === billId) ? null : billId
      }

      const displayFile = (fileMetaData: ResourceObject<string, FMDModel>, shouldDownload: boolean = false) => {
        root.$store.dispatch('file_meta_datas/display_file', { fmd: fileMetaData, shouldDownload: shouldDownload })
      }

      const selectedBill = computed(() => {
        if (!selectedBillId.value) return {}
        return bills.value[selectedBillId.value] || {}
      })

      const selectedBillEntries = computed(() => {
        if (!selectedBillId.value) return {}
        const selBill = bills.value[selectedBillId.value]
        if (!selBill) return {}
        const result = {charge:[], credit: [], avi: [], beCount: 0}
        selBill.attributes?.entries.forEach((be:any) => {
          if (be.attributes.manual) {
          // if (true) {
            result.beCount += 1
            result[be.attributes.type].push(be)
          }
        })
        return result
      })

      const billKeyData = computed(() => {
        if (!selectedBill.value) return null
        const pdf = pdfData.value[selectedBill.value.id]
        if (!pdf) return null
        const info = {
          'Rechnungsnummer': selectedBill.value.attributes.billNr,
          'Bezahlt': selectedBill.value.attributes.paid,
          'Geschützt': selectedBill.value.attributes.locked,
          'Pflegeabschnitte': pdf.provision_count,
          'Erworbene Leads': pdf.leads_count,
          'Reklamierte Leads': pdf.reclaim_leads_count,
        }
        const tmpPositions = pdf.charges.reduce((res, e) => {
          if (res[e.label]) {
            res[e.label] += pdfStringToCents(e.gross_value_string)
          } else {
            res[e.label] = pdfStringToCents(e.gross_value_string)
          }
          return res
        }, {})
        pdf.credits.reduce((res, e) => {
          if (res[e.label]) {
            res[e.label] += -pdfStringToCents(e.gross_value_string)
          } else {
            res[e.label] = -pdfStringToCents(e.gross_value_string)
          }
          return res
        }, tmpPositions)
        pdf.manual_avis.reduce((res, e) => {
          if (res[e.label]) {
            res[e.label] += pdfStringToCents(e.gross_value_string)
          } else {
            res[e.label] = pdfStringToCents(e.gross_value_string)
          }
          return res
        }, tmpPositions)
        let netTotal = 0
        const positions = Object.keys(tmpPositions).reduce((res, k) => {
          // res[k] = (tmpPositions[k]/100).toString().replace('.',',')
          res[k] = floatTopdfString(tmpPositions[k]/100)
          // console.log('res[k]: ', k, tmpPositions[k], res[k])
          return res
        },{})
        const totals = {'Total': pdf.avis_totals.total_string}
        return {id: selectedBill.value.id, info, positions, totals}
      })

      const selectedBillPdf = computed(() => {
        const fmdId = selectedBill.value?.relationships?.fileMetaData?.data?.id
        if (fmdId) {
          return [fMDs.value[fmdId]]
        } else {
          return null
        }
      })


      const updateBill = (bill:any) => {
        showLoading.value = true
        root.$store.dispatch('seller_bills/edit', {id: bill.id, body: bill})
          .then((r) => { reloadSelectedBill() })
          .finally(() => {showLoading.value = false})
      }

      const pdfGenerate = () => updateBill({id: selectedBillId.value, usecase: 'render_pdf'})

      const toggleLocked = async () => {
        const bill = selectedBill.value
        if (!bill) {return null}
        const lockedState = bill.attributes?.locked
        const paidState = bill.attributes?.paid
        if (paidState && lockedState) {
          prompt(root, 'Zuerst muss die Rechnung auf "nicht gezahlt" gestellt werden')
        } else {
          const unlockMsg = "Diese Rechnung ist geschützt. Den Schutz aufzuheben kann dazu führen, dass Pflegeabschnitte geändert werden und Rechnungen sich verändern, die nicht geändert werden sollen. Bitte nur fortsetzen, wenn klar ist was passieren soll."
          const lockMsg = "Der Schreibschutz wird dazu führen, dass Pflegeabschnitte nicht mehr bearbeitet werden können. Soll fortgefahren werden?"
          const msg = lockedState ? unlockMsg : lockMsg
          const confirmation = await confirm(root, msg)
          if ( confirmation ) {
            const usecase = lockedState ? 'set_unlocked' : 'set_locked'
            showLoading.value = true
            updateBill({ id: bill.id, usecase: usecase })
          }
        }
      }

      const togglePaid = () => {
        setBillPaid(selectedBill.value)
      }

      const setBillPaid = async (bill) => {
        const paidState = bill.attributes?.paid
        const paidMessage = `Rechnung: ${bill.attributes.billNr} ist als bezahlt markiert. Das bedeutet ein Banktransfer hat stattgefunden. Diesen Status zu wechseln kann großen Arbeitsaufwand mit sich bringen, die Rechnungen zu korrigieren. Soll wirklich forgefahren werden?`
        const unpaidMessage = `Rechnung: ${bill.attributes.billNr} wird als gezahlt markiert. Bitte nur fortfahren, wenn für diese Rechnung tatsächlich ein Banktransfer stattgefunden hat.`
        const confirmMessage = paidState ? paidMessage : unpaidMessage
        const confirmation = await confirm(root, confirmMessage)
        if (confirmation) {
          const usecase = paidState ? 'set_unpaid' : 'set_paid'
          // selectedBill.value.attributes.paid = true
          showLoading.value = true
          updateBill({ id: bill.id, usecase: usecase })
        }
      }

      const selectedPdfData = computed( () => {
        if (selectedBillId.value) {
          return pdfData.value[selectedBillId.value]
        } else { return null }
      })

      const updateBillEntries = (msg: string, payload: any) => {
        console.log('AgencyBill#updateBillEntries', msg, payload)
        if (msg === 'entry_create') {
          console.log('updateBillEntries entry_create; msg: ', msg, 'payload: ', payload)
          selectedBill.value.attributes.entries.push(payload.resource)
        } else if (msg === 'entry_destroy') {
          selectedBill.value.attributes.entries = selectedBill.value.attributes.entries.filter((e) => e.id !== payload.id)
        } else if (msg === 'entry_update') {
          const presentEntry = selectedBill.value.attributes.entries.find((e) => e.id === payload.resource.id)
          console.log('updateBillEntries entry_update; msg: ', msg, 'payload: ', payload, 'presentEntry: ', presentEntry)
          presentEntry.attributes = {...presentEntry.attributes, ...payload.resource.attributes}
        }
        pdfData.value[selectedBill.value.id] = payload.pdfData
      }

      const grandTotal = computed(() => {
        const reducer = (res:number, e:any) => {
          res = res + pdfStringToCents(e.monthTotal)
          return res
        }
        return overviewTableItems.value.reduce(reducer, 0)/100
      })

      return {
        showLoading,
        yearOptions,
        year,
        monthOptions,
        month,
        fullName,
        initialDataRequest,
        bills,
        selectedPdfData,
        sellers,
        fMDs,
        selectedSellerId,
        selectedBillId,
        selectedBill,
        billDate,
        overviewTableColumns,
        overviewTableItems,
        overviewTableOptions,
        sellerTableItems,
        sellerTableColumns,
        billKeyData,
        selectedBillPdf,
        togglePaid,
        updateBill,
        toggleLocked,
        pdfGenerate,
        reloadSelectedBill,
        provisionColumns,
        leadColumns,
        selectedBillEntries,
        updateBillEntries,
        grandTotal
      }
    }
  })
