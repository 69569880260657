
  import { defineComponent, reactive, ref, computed, getCurrentInstance } from "vue"
  import { confirm, prompt } from '@/utils/interactionModals'
  import { shortDate } from '@/utils/helper'
  import { floatTopdfString } from '@/utils/billHelper'
  import moment from "moment"

  export default defineComponent({
    props: {
      bill: {
        type: Object,
        required: true
      },
      entry: { type: Object },
      edit: {
        type: Boolean,
        default: false
      }
    },

    setup(props, { emit }) {
      const root = getCurrentInstance().proxy
      const showLoading = ref(false)
      const newBillEntry = () => {
        return {
          id: 'new',
          type: 'bill_entries',
          attributes: {
            type: 'avi',
            billNr: props.bill.attributes?.billNr,
            billDate: props.bill.attributes?.billDate,
            label: '',
            count: 1,
            valuePerUnit: 0,
            deferred: true
          },
        }
      }
      const ent = ref(props.entry || newBillEntry())
      const localEdit = ref(props.edit)
      const toggleEdit = () => {localEdit.value = !localEdit.value}
      const typeOptions = [
        // { value: null, text: 'Please select an option' },
        { value: 'charge', text: 'Leistung' },
        { value: 'credit', text: 'Gutschrift' },
        { value: 'avi', text: 'Avi' },
      ]
      const netValue = computed(() => {
        const vpu = ent.value.attributes.valuePerUnit
        const count = ent.value.attributes.count
        return (Math.round(vpu*100) * count)/100
      })
      const grossValue = computed(() => {
        const netInt = Math.round(netValue.value*100)
        const taxFactor = isAvi.value ? 1 : 1+parseFloat(props.bill.attributes.taxRate)
        return Math.round(netInt * taxFactor)/100
      })
      const submit = (usecase: string) => {
        const data = {id: props.bill.id, billEntry: ent.value}
        showLoading.value = true
        root.$store.dispatch(`seller_bills/${usecase}`, data).then((r) => {
          console.log('BillEntry#submit', usecase, r, ent.value)
          emit('entry-changed', usecase, r.data)
          if (usecase === 'entry_create') {
            ent.value = newBillEntry()
          } else if (usecase === 'entry_update') { toggleEdit() }
        }).finally(() => showLoading.value = false)
      }

      const destroy = async () => {
        const msg = `Soll der Eintrag: >${ent.value.attributes.label}< gelöscht werden?`
        const confirmation = await confirm(root, msg)
        if (confirmation) {
          const data = {id: props.bill.id, billEntry: ent.value}
          const usecase = 'entry_destroy'
          showLoading.value = true
          root.$store.dispatch(`seller_bills/${usecase}`, data)
            .then((r) => {
              emit('entry-changed', usecase, r.data)
            })
            .finally(() => showLoading.value = false)
        }
      }

      const type = computed( () => ent.value.attributes.type )

      const billDate = computed({
        get() { return moment(ent.value.attributes.billDate).format('YYYY-MM-DD') },
        set(newValue) { ent.value.attributes.billDate = newValue }
      })
      const isNew = computed(() => ent.value.id === 'new')
      const isAvi = computed(() => ent.value.attributes.type === 'avi')
      const typeChanged = () => { ent.value.attributes.deferred = isAvi }

      return {
        showLoading,
        isNew,
        isAvi,
        localEdit,
        toggleEdit,
        billDate,
        submit,
        destroy,
        typeOptions,
        netValue,
        grossValue,
        ent,
        type,
        shortDate,
        floatTopdfString,
        typeChanged
      }
    }
  })
